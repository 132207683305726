module.exports = [{
      plugin: require('../node_modules/@superrb/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-2JQ6T9C9LF","cookieName":"ad-google-analytics-tag","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"G-2JQ6T9C9LF","cookieName":"ad-google-analytics-tag","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/icons/Hyand_Plus_ani.gif","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f03bd93f1e1066ad3f9b8c1708e1f47"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
